import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";

// Field: Text Field
import { TextFieldInForm } from "@/angular-blocworx/components/fields/text/text-field-in-form.component";
import { TextFieldInData } from "@/angular-blocworx/components/fields/text/text-field-in-data.component";
import {
    TextFieldCreateEditRadioButton,
} from "@/angular-blocworx/components/fields/text/text-field-create-edit-radio-button.component";
import {
    TextFieldCreateEditPopup,
} from "@/angular-blocworx/components/fields/text/text-field-create-edit-popup.component";
import { TextFieldServiceBLCXAU } from "@/angular-blocworx/components/fields/text/text.service";
import { MathsFactoryBLCXAU } from "@/angular-blocworx/components/fields/maths/maths.factory";
import { RuleService } from "@/angular-blocworx/components/rule/service/rule.service";
import { ScanStationService } from "@/angular-blocworx/components/scanStation/scan-station-service";
import { ScanStationComponent } from "@/angular-blocworx/components/scanStation/scan-station.component";


angular.module('BlocworxModule')
    .directive('textFieldInForm', downgradeComponent({component: TextFieldInForm}))
    .directive('textFieldInData', downgradeComponent({component: TextFieldInData}))
    .directive('textFieldCreateEditRadioButton', downgradeComponent({component: TextFieldCreateEditRadioButton}))
    .directive('textFieldCreateEditPopup', downgradeComponent({component: TextFieldCreateEditPopup}))
    .directive('wysiwygCreateEditFieldRadioButton', downgradeComponent({component: WysiwygCreateEditFieldRadioButton}))
    .directive('wysiwygCreateEditFieldPopup', downgradeComponent({component: WysiwygCreateEditFieldPopup}));

angular.module("BlocworxModule")
    .factory("TextFieldServiceBLCXAU", downgradeInjectable(TextFieldServiceBLCXAU));

angular.module("BlocworxModule")
    .factory("MathsFactoryBLCXAU", downgradeInjectable(MathsFactoryBLCXAU));

// Calendar Resource Planning
import {
    CalendarResourcePlanningInForm,
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-form.component";
import {
    CalendarResourcePlanningInData,
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-data.component";
import {
    CalendarResourcePlanningEditRadioButton,
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-edit-radio-button.component";
import {
    CalendarResourcePlanningCreateEditPopup,
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-create-edit-popup.component";

angular.module("BlocworxModule")
    .directive("calendarResourcePlanningInForm", downgradeComponent({ component: CalendarResourcePlanningInForm }))
    .directive("calendarResourcePlanningInData", downgradeComponent({ component: CalendarResourcePlanningInData }))
    .directive("calendarResourcePlanningEditRadioButton", downgradeComponent({ component: CalendarResourcePlanningEditRadioButton }))
    .directive("calendarResourcePlanningCreateEditPopup", downgradeComponent({ component: CalendarResourcePlanningCreateEditPopup }));

import {
    CalendarResourcePlanningService,
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";

angular.module("BlocworxModule")
    .factory("CalendarResourcePlanningService", downgradeInjectable(CalendarResourcePlanningService));

import {
    TemplateExporterInFormComponent
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter-in-form.component";

import {
    TemplateExporterInDataComponent
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter-in-data.component";

import {
    TemplateExporterEditRadioButtonComponent
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter-edit-radio-button.component";

import {
    TemplateExporterCreateEditPopupComponent
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter-create-edit-popup.component";

angular.module("BlocworxModule")
    .directive("templateExporterInForm", downgradeComponent({ component: TemplateExporterInFormComponent }))
    .directive("templateExporterInData", downgradeComponent({ component: TemplateExporterInDataComponent }))
    .directive("templateExporterEditRadioButton", downgradeComponent({ component: TemplateExporterEditRadioButtonComponent }))
    .directive("templateExporterCreateEditPopup", downgradeComponent({ component: TemplateExporterCreateEditPopupComponent }));

import {
    TemplateExporterServiceFoField
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter.service";

angular.module("BlocworxModule").factory("TemplateExporterServiceFoField", downgradeInjectable(TemplateExporterServiceFoField));


angular.module("BlocworxModule")
    .service("RuleService", downgradeInjectable(RuleService));

import {
    ChangeBlocModule,
} from "@/angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.component";

angular.module("BlocworxModule")
    .directive("changeBlocModule", downgradeComponent({ component: ChangeBlocModule }));

import { AutocompleteSearch } from "@/angular-blocworx/components/autocompleteSearch/autocomplete-search.component";

angular.module("BlocworxModule")
    .directive("autocompleteSearch", downgradeComponent({ component: AutocompleteSearch }));

import { VersionControl } from "@/angular-blocworx/components/admin/version-control/version-control.component";

angular.module("BlocworxModule")
    .directive("versionControl", downgradeComponent({ component: VersionControl }));

// Evervault
import {
    EvervaultAppSetting,
} from "@/angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.component";

angular.module("BlocworxModule")
    .directive("evervaultAppSetting", downgradeComponent({ component: EvervaultAppSetting }));

import { EvervaultService } from "@/angular-blocworx/components/admin/app-setting/evervault/evervault.service";

angular.module("ConfigurationModule")
    .factory("EvervaultService", downgradeInjectable(EvervaultService));

// Manage Data Settings
import {
    FindandReplace,
} from "@/angular-blocworx/components/manageData/find-and-replace/find-and-replace.component";

angular.module("BlocworxModule")
    .directive("findAndReplace", downgradeComponent({ component: FindandReplace }));

import { ManageDataService } from "@/angular-blocworx/components/manageData/manageData.service";

angular.module("ConfigurationModule")
    .factory("ManageDataService", downgradeInjectable(ManageDataService));

import {
    ReplaceConfirmation,
} from "@/angular-blocworx/components/manageData/find-and-replace/replace-confirmation.component";

angular.module("BlocworxModule")
    .directive("replaceConfirmation", downgradeComponent({ component: ReplaceConfirmation }));

import {
    ReplacedData,
} from "@/angular-blocworx/components/manageData/find-and-replace/replaced-data.component";

angular.module("BlocworxModule")
    .directive("replacedData", downgradeComponent({ component: ReplacedData }));

// Field: Results Box
import { ResultsBoxInForm } from "@/angular-blocworx/components/fields/results-box/results-box-in-form.component";
import { ResultsBoxInData } from "@/angular-blocworx/components/fields/results-box/results-box-in-data.component";
import {
    ResultsBoxCreateEditRadioButton,
} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-radio-button.component";
import {
    ResultsBoxCreateEditPopup,
} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-popup.component";
import { ResultsBoxService } from "@/angular-blocworx/components/fields/results-box/results-box.service";

angular.module("BlocworxModule").directive("resultsBoxInForm", downgradeComponent({ component: ResultsBoxInForm }))
    .directive("resultsBoxInData", downgradeComponent({ component: ResultsBoxInData }))
    .directive("resultsBoxCreateEditRadioButton", downgradeComponent({ component: ResultsBoxCreateEditRadioButton }))
    .directive("resultsBoxCreateEditPopup", downgradeComponent({ component: ResultsBoxCreateEditPopup }))
    .factory("ResultsBoxService", downgradeInjectable(ResultsBoxService));

// Grid
import { GridService } from "@/angular-blocworx/components/grid/grid.service";

angular.module("BlocworxModule").factory("GridService", downgradeInjectable(GridService));

angular.module("BlocworxModule").directive("resultsBoxInForm", downgradeComponent({ component: ResultsBoxInForm }));

angular.module("BlocworxModule").directive("scanStationComponent", downgradeComponent({ component: ScanStationComponent }));
angular.module("BlocworxModule").factory("AngularScanStationService", downgradeInjectable(ScanStationService));

import {
    ManageAndBuildTemplatesComponent,
} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.component";
import angular from "angular";

angular.module("BlocworxModule").directive("manageAndBuildTemplates", downgradeComponent({ component: ManageAndBuildTemplatesComponent }));

import {
    DataEditHistory,
} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.component";

angular.module("BlocworxModule").directive("somethingElse", downgradeComponent({ component: DataEditHistory }));

import {DataEditHistoryService} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.service";
angular.module('BlocworxModule').factory('DataEditHistoryService', downgradeInjectable(DataEditHistoryService));

import {TemplateExporter} from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.component";
angular.module('BlocworxModule').directive('templateExporter', downgradeComponent({component: TemplateExporter}));

// DeleteAllData
import { DeleteAllData } from "@/angular-blocworx/components/deleteAllData/delete-all-data.component";

angular.module("BlocworxModule")
    .directive("deleteAllData", downgradeComponent({ component: DeleteAllData }));

import { DeleteAllDataConfirmation } from "@/angular-blocworx/components/deleteAllData/delete-all-data-confirmation.component";

angular.module("BlocworxModule")
    .directive("deleteAllDataConfirmation", downgradeComponent({ component: DeleteAllDataConfirmation }));

import {DeleteAllDataService} from "@/angular-blocworx/components/deleteAllData/delete-all-data.service";

angular.module('BlocworxModule').factory('DeleteAllDataService', downgradeInjectable(DeleteAllDataService));

import { LookUpData } from "@/angular-blocworx/components/manageData/look-up-data/look-up-data.component";

angular.module("BlocworxModule")
    .directive("lookUpData", downgradeComponent({ component: LookUpData }));

import { LookUpDataResults } from "@/angular-blocworx/components/manageData/look-up-data/look-up-data-results.component";

angular.module("BlocworxModule")
    .directive("lookUpDataResults", downgradeComponent({ component: LookUpDataResults }));

import { ManageData } from "@/angular-blocworx/components/manageData/manage-data.component";

angular.module("BlocworxModule")
    .directive("manageData", downgradeComponent({ component: ManageData }));

import {TemplateList} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.component";
angular.module('BlocworxModule').directive('templateList', downgradeComponent({component: TemplateList}));

import {TemplateListService} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.service";
angular.module('BlocworxModule').factory('TemplateListService', downgradeInjectable(TemplateListService));

import {TemplateListEdit} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list-edit.component";
angular.module('BlocworxModule').directive('templateListEdit', downgradeComponent({component: TemplateListEdit}));

// Edit Page Details Settings
import {EditPageDetails} from "@/angular-blocworx/components/page/edit-page-details/edit-page-details.component";
angular.module('BlocworxModule')
    .directive('editPageDetails', downgradeComponent({component: EditPageDetails}));

// Edit Page Main Settings
import {EditPageHeader} from "@/angular-blocworx/components/page/edit-page-header/edit-page-header.component";
angular.module('BlocworxModule')
    .directive('editPageHeader', downgradeComponent({component: EditPageHeader}));

// Edit Page Sections Pages Modules Settings
import {EditPageSectionsPagesModules} from "@/angular-blocworx/components/page/edit-page-sections-pages-modules/edit-page-sections-pages-modules.component";
angular.module('BlocworxModule')
    .directive('editPageSectionsPagesModules', downgradeComponent({component: EditPageSectionsPagesModules}));

// Edit Page Footer Settings
import {EditPageFooter} from "@/angular-blocworx/components/page/edit-page-footer/edit-page-footer.component";
angular.module('BlocworxModule')
    .directive('editPageFooter', downgradeComponent({component: EditPageFooter}));

// Angular Page Settings
import {PageComponent} from "@/angular-blocworx/components/page/page.component";
angular.module('BlocworxModule')
    .directive('pageComponent', downgradeComponent({component: PageComponent}));

import {PageService} from "@/angular-blocworx/components/page/page.service";
angular.module('ConfigurationModule')
    .factory('PageService', downgradeInjectable(PageService));

// TinyMCE Service
import {TinymceService} from "@/angular-blocworx/components/page/tinymce.service";
angular.module('ConfigurationModule')
    .factory('TinymceService', downgradeInjectable(TinymceService));

// Angular WYSIWYG Settings
import {WysiwygCreateEditFieldRadioButton} from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-radio-button.component";
import {WysiwygCreateEditFieldPopup} from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-popup.component";
import {WysiwygFieldInForm} from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-in-form.component";
import {WysiwygFieldInData} from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-in-data.component";
import {WysiwygFieldServiceBLCXAU} from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg.service";
import {WysiwygForUserFieldInData} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-in-data.component";
import {WysiwygForUserFieldInForm} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-in-form.component";
import {WysiwygForUserCreateEditFieldRadioButton} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-radio-button.component";
import {WysiwygForUserCreateEditFieldPopup} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-popup.component";
import {WysiwygForUserFieldServiceBLCXAU} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user.service";
import {IsVisibleService} from "@/angular-blocworx/components/fields/wysiwyg-for-user/is-visible.service";
import {TinyMceContainer} from "@/angular-blocworx/components/fields/wysiwyg/tiny-mce-container.component";


angular.module('BlocworxModule')
    .directive('wysiwygCreateEditFieldRadioButton', downgradeComponent({component: WysiwygCreateEditFieldRadioButton}))
    .directive('wysiwygCreateEditFieldPopup', downgradeComponent({component: WysiwygCreateEditFieldPopup}))
    .directive('wysiwygFieldInForm', downgradeComponent({component: WysiwygFieldInForm}))
    .directive('wysiwygFieldInData', downgradeComponent({component: WysiwygFieldInData}))
    .directive('wysiwygForUserFieldInData', downgradeComponent({component: WysiwygForUserFieldInData}))
    .directive('wysiwygForUserFieldInForm', downgradeComponent({component: WysiwygForUserFieldInForm}))
    .directive('wysiwygForUserCreateEditFieldRadioButton', downgradeComponent({component: WysiwygForUserCreateEditFieldRadioButton}))
    .directive('wysiwygForUserCreateEditFieldPopup', downgradeComponent({component: WysiwygForUserCreateEditFieldPopup}))
    .directive('tinyMceContainer', downgradeComponent({component: TinyMceContainer}));


angular.module('BlocworxModule')
    .factory('WysiwygFieldServiceBLCXAU', downgradeInjectable(WysiwygFieldServiceBLCXAU))
    .factory('WysiwygForUserFieldServiceBLCXAU', downgradeInjectable(WysiwygForUserFieldServiceBLCXAU))
    .factory('IsVisibleService', downgradeInjectable(IsVisibleService));



// Field: HTML
import {HtmlFieldCreateEditPopup} from "@/angular-blocworx/components/fields/html/html-field-create-edit-popup.component";
import {HtmlFieldCreateEditRadioButton} from "@/angular-blocworx/components/fields/html/html-field-create-edit-radio-button.component";
import {HtmlFieldInData} from "@/angular-blocworx/components/fields/html/html-field-in-data.component";
import {HtmlFieldInForm} from "@/angular-blocworx/components/fields/html/html-field-in-form.component";
import {HtmlFieldService} from "@/angular-blocworx/components/fields/html/html.service";

angular.module('BlocworxModule')
    .directive('htmlFieldCreateEditPopup', downgradeComponent({component: HtmlFieldCreateEditPopup}))
    .directive('htmlFieldCreateEditRadioButton', downgradeComponent({component: HtmlFieldCreateEditRadioButton}))
    .directive('htmlFieldInData', downgradeComponent({component: HtmlFieldInData}))
    .directive('htmlFieldInForm', downgradeComponent({component: HtmlFieldInForm}));

angular.module('BlocworxModule')
    .factory('HtmlFieldService', downgradeInjectable(HtmlFieldService));

// Field: Separator
import {SeparatorNewFieldCreateEditPopup} from "@/angular-blocworx/components/fields/separator/separator-field-create-edit-popup.component";
import {SeparatorFieldCreateEditRadioButton} from "@/angular-blocworx/components/fields/separator/separator-field-create-edit-radio-button.component";
import {SeparatorFieldInData} from "@/angular-blocworx/components/fields/separator/separator-field-in-data.component";
import { SeparatorFieldService } from "@/angular-blocworx/components/fields/separator/separator.service";
import {SeparatorFieldInForm} from "@/angular-blocworx/components/fields/separator/separator-field-in-form.component";

angular.module('BlocworxModule')
    .directive('separatorFieldCreateEditPopup', downgradeComponent({component: SeparatorNewFieldCreateEditPopup}))
    .directive('separatorFieldCreateEditRadioButton', downgradeComponent({component: SeparatorFieldCreateEditRadioButton}))
    .directive('separatorFieldInData', downgradeComponent({component: SeparatorFieldInData}))
    .directive('separatorFieldInForm', downgradeComponent({component: SeparatorFieldInForm}));

angular.module('BlocworxModule')
    .factory('SeparatorFieldService', downgradeInjectable(SeparatorFieldService));
// Legacy-features
import {LegacyFeaturesAppSetting} from "@/angular-blocworx/components/admin/app-setting/legacy-features/legacy-features-app-setting.component";
angular.module('BlocworxModule')
    .directive('legacyFeaturesAppSetting', downgradeComponent({component: LegacyFeaturesAppSetting}));

import {LegacyFeaturesService} from "@/angular-blocworx/components/admin/app-setting/legacy-features/legacy-features.service";
angular.module('ConfigurationModule')
    .factory('LegacyFeaturesService', downgradeInjectable(LegacyFeaturesService));

// Schedulers
import {SchedulersAppSetting} from "@/angular-blocworx/components/admin/app-setting/schedulers/schedulers-app-setting.component";
angular.module('BlocworxModule')
    .directive('schedulersAppSetting', downgradeComponent({component: SchedulersAppSetting}))

import {SchedulersService} from "@/angular-blocworx/components/admin/app-setting/schedulers/schedulers.service";
angular.module('ConfigurationModule')
    .factory('SchedulersService', downgradeInjectable(SchedulersService));

// Languages
import {LanguagesAppSetting} from "@/angular-blocworx/components/admin/app-setting/languages/languages-app-setting.component";
angular.module('BlocworxModule')
    .directive('languagesAppSetting', downgradeComponent({component: LanguagesAppSetting}))

import {LanguagesService} from "@/angular-blocworx/components/admin/app-setting/languages/languages.service";
angular.module('ConfigurationModule')
    .factory('LanguagesService', downgradeInjectable(LanguagesService));

// Login Attempts
import {LoginAttemptsAppSetting} from "@/angular-blocworx/components/admin/app-setting/login-attempts/login-attempts-app-setting.component";
angular.module('BlocworxModule')
    .directive('loginAttemptsAppSetting', downgradeComponent({component: LoginAttemptsAppSetting}))

import {LoginAttemptsService} from "@/angular-blocworx/components/admin/app-setting/login-attempts/login-attempts.service";
angular.module('ConfigurationModule')
    .factory('LoginAttemptsService', downgradeInjectable(LoginAttemptsService));

// Timeout Setting
import {TimeoutAppSetting} from "@/angular-blocworx/components/admin/app-setting/timeout/timeout-app-setting.component";
angular.module('BlocworxModule')
    .directive('timeoutAppSetting', downgradeComponent({component: TimeoutAppSetting}));

import {TimeoutService} from "@/angular-blocworx/components/admin/app-setting/timeout/timeout.service";
angular.module('ConfigurationModule')
    .factory('TimeoutService', downgradeInjectable(TimeoutService));

// Sign-in-google Settings
import {SignInGoogleAppSetting} from "@/angular-blocworx/components/admin/app-setting/sign-in-google/sign-in-google-app-setting.component";
angular.module('BlocworxModule')
    .directive('signInGoogleAppSetting', downgradeComponent({component: SignInGoogleAppSetting}));

import {SignInGoogleService} from "@/angular-blocworx/components/admin/app-setting/sign-in-google/sign-in-google.service";
angular.module('ConfigurationModule')
    .factory('SignInGoogleService', downgradeInjectable(SignInGoogleService));

// App Settings
import {AppSettings} from "@/angular-blocworx/components/admin/app-setting/app-setting.component";
angular.module('BlocworxModule')
    .directive('appSettings', downgradeComponent({component: AppSettings}));

import { AppSettingService } from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

angular.module("ConfigurationModule")
    .factory("AppSettingService", downgradeInjectable(AppSettingService));

